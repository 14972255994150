import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import upperFirst from 'lodash/upperFirst';
import pick from 'lodash/pick';
import PageHeader, { Header2 } from '../generic/PageHeader';
import ContentSeparator from '../generic/ContentSeparator';
import StandardCollection from '../promo/collections/StandardCollection';
import StandardCollection6Promos from '../promo/collections/StandardCollection6Promos';
import Hero from '../promo/Hero';
import AzPage from '../generic/aToZ/Main';
import SeeAllRecipesLink from '../generic/SeeAllRecipesLink';
import { promoShape } from '../promo/shapes';
import MetaProperties from '../generic/MetaProperties';

if (process.env.CLIENT) {
  require('./Page.scss'); // eslint-disable-line global-require
}

export const Page = ({
  azCounts,
  ogImage,
  description,
  hero,
  id,
  name,
  relatedCollections,
  relatedMenus,
  relatedRecipes,
  relatedDishes,
  relatedIngredients,
  title,
  activeTab,
  page,
  letter,
  totalCount,
}) => {
  const hasRelatedMenus = relatedMenus && relatedMenus.length > 0;
  const hasRelatedCollections = relatedCollections && relatedCollections.length > 0;
  const hasRelatedDishes = relatedDishes && relatedDishes.length > 0;
  const hasRelatedIngredients = relatedIngredients && relatedIngredients.length > 0;
  const baseUrl = `/food/occasions/${id}`;

  return (
    <>
      <MetaProperties
        title={`${name} recipes`}
        description={description}
        imageUrl={ogImage}
        canonical={`${baseUrl}/occasions/${id}`}
      />
      <PageHeader title={title} description={description} />
      {hero && <Hero {...hero} />}
      <ContentSeparator />

      {hasRelatedMenus && (
        <>
          <StandardCollection
            containerClassName="occasion-page__menus-collection"
            id={`occasion-${id}-menus`}
            title={`${name} menus`}
            promos={relatedMenus}
            maxCollectionSize={relatedMenus.length}
          />
          <ContentSeparator />
        </>
      )}
      {hasRelatedCollections && (
        <>
          <StandardCollection
            containerClassName="occasion-page__collections-collection"
            id={`occasion-${id}-collections`}
            title={`${name} collections`}
            promos={relatedCollections}
            maxCollectionSize={relatedCollections.length}
          />
          <ContentSeparator />
        </>
      )}
      {relatedRecipes.length > 0 && (
        <>
          <Header2 title={`${upperFirst(name)} recipes`} anchor="recipes" />
          <AzPage
            azCounts={azCounts}
            baseUrl={baseUrl}
            activeTab={activeTab}
            entity="recipes"
            letter={letter}
            page={page}
            promos={relatedRecipes}
            promoContainerClassName="occasion-page"
            anchor="recipes"
            totalCount={totalCount}
          />
          {activeTab === 'featured' && (
            <div className="see-all-recipes__wrap gel-wrap">
              <SeeAllRecipesLink
                body={`all ${name} recipes`}
                totalCount={totalCount}
                href={`/food/search?occasions=${id}`}
              />
            </div>
          )}
        </>
      )}
      {(hasRelatedDishes || hasRelatedIngredients) && (
        <div className="food-body__grey-background">
          {hasRelatedDishes && (
            <>
              <StandardCollection6Promos
                containerClassName="occasion-page__related-dishes-collection"
                id={`occasion-${id}-related-dishes`}
                title="Related dishes"
                maxCollectionSize={relatedDishes.length}
                promos={relatedDishes}
              />
              <ContentSeparator />
            </>
          )}
          {hasRelatedIngredients && (
            <>
              <StandardCollection6Promos
                containerClassName="occasion-page__related-ingredients-collection"
                id={`occasion-${id}-related-ingredients`}
                title="Related ingredients"
                maxCollectionSize={relatedIngredients.length}
                promos={relatedIngredients}
              />
              <ContentSeparator />
            </>
          )}
        </div>
      )}
    </>
  );
};

Page.propTypes = {
  ogImage: PropTypes.string,
  hero: PropTypes.shape(promoShape),
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  relatedCollections: PropTypes.arrayOf(PropTypes.shape(promoShape)),
  relatedMenus: PropTypes.arrayOf(PropTypes.shape(promoShape)),
  relatedDishes: PropTypes.arrayOf(PropTypes.shape(promoShape)),
  relatedIngredients: PropTypes.arrayOf(PropTypes.shape(promoShape)),
  relatedRecipes: PropTypes.arrayOf(PropTypes.shape(promoShape)),
  title: PropTypes.string,
  activeTab: PropTypes.oneOf(['all', 'featured']),
  azCounts: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  page: PropTypes.number,
  letter: PropTypes.string,
  totalCount: PropTypes.number.isRequired,
};

Page.defaultProps = {
  ogImage: '',
  hero: undefined,
  relatedCollections: [],
  relatedMenus: [],
  relatedDishes: [],
  relatedIngredients: [],
  relatedRecipes: [],
  title: undefined,
  activeTab: 'featured',
  azCounts: {},
  letter: '',
  page: 1,
};

export default connect(state => ({
  ...pick(state.occasionReducer, [
    'azCounts',
    'ogImage',
    'description',
    'hero',
    'id',
    'name',
    'totalCount',
    'relatedCollections',
    'relatedMenus',
    'relatedDishes',
    'relatedIngredients',
    'relatedRecipes',
    'title',
    'activeTab',
    'page',
    'letter',
  ]),
}))(Page);
